import Layout from './Layout';
import {useLocaleContextActions} from 'tovera-shared/context/LocaleContext';

export default function ErrorComponent({error}) {
    const {translate} = useLocaleContextActions();

    return (
        <Layout>
            <div>
                {translate('common.error_with_message', {
                    message: error.errorCode
                })}
            </div>
        </Layout>
    );
}
