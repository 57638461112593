import {useState} from 'react';
import Script from 'next/script';
import {useMsal} from '@azure/msal-react';
import {DocumentDuplicateIcon} from '@heroicons/react/outline';
import {ToastContainer} from 'react-toastify';
import DynamicSidebar from './DynamicSidebar';
import MenuButton from './MenuButton';
import Sidebar from './Sidebar';

const contextClass = {
    success: 'bg-green-300 text-green-800',
    error: 'bg-red-500',
    info: 'bg-gray-500',
    warning: 'bg-orange-500',
    default: 'bg-indigo-500',
    dark: 'bg-white-500 font-gray-300'
};

const navigation = [
    {
        name: 'Assets',
        href: '/assets',
        current: false,
        icon: DocumentDuplicateIcon
    }
];

export default function Layout({subHeaderTitle, actionComponent, children}) {
    const {instance} = useMsal();
    const account = instance.getActiveAccount();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <>
            <Script>
                {process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true' &&
                    account &&
                    `${posthog.identify(account.homeAccountId, {
                        username: account.username
                    })}`}
            </Script>
            <div className="h-screen flex overflow-hidden bg-white">
                <ToastContainer
                    toastClassName={({type}) =>
                        contextClass[type || 'default'] +
                        ' relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer font-sans text-sm font-semibold'
                    }
                />
                <DynamicSidebar
                    navigation={navigation}
                    sidebarOpen={sidebarOpen}
                    handleSidebarClose={() => setSidebarOpen(false)}
                />
                <Sidebar navigation={navigation} />
                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                    <MenuButton
                        handleSidebarOpen={() => setSidebarOpen(true)}
                    />
                    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                        <div className="py-6">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex sm:mb-16 mb-10 items-center">
                                {subHeaderTitle && (
                                    <h1 className="text-3xl font-normal font-display text-gray-900">
                                        {subHeaderTitle}
                                    </h1>
                                )}
                                {actionComponent && (
                                    <div className="ml-auto">
                                        {actionComponent}
                                    </div>
                                )}
                            </div>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                {children}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
