import { Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

import AssetUsage from './AssetUsage'
import AvatarMenu from './AvatarMenu'
import NavLink from './NavLink'

export default function DynamicSidebar({
    navigation,
    sidebarOpen,
    handleSidebarClose,
}) {
    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed inset-0 flex z-40 md:hidden"
                open={sidebarOpen}
                onClose={handleSidebarClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-light">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    onClick={handleSidebarClose}
                                >
                                    <span className="sr-only">
                                        Close sidebar
                                    </span>
                                    <XIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </Transition.Child>
                        <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img
                                    className="h-8 w-auto"
                                    src="tovera-logo.svg"
                                    alt="tovera logo"
                                />
                            </div>
                            <nav className="mt-5 px-2 space-y-1">
                                {navigation.map((item) => (
                                    <NavLink
                                        key={item.name}
                                        href={item.href}
                                        activeClassName="text-black group flex items-center px-2 py-2 text-sm font-medium"
                                    >
                                        <a className="text-gray-medium hover:text-black group flex items-center px-2 py-2 text-sm font-medium">
                                            <item.icon
                                                className="mr-2 flex-shrink-0 h-6 w-6"
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </a>
                                    </NavLink>
                                ))}
                            </nav>
                        </div>
                        <div className="p-4 border-t border-gray-border">
                            <AvatarMenu />
                            <div className="mt-2">
                                <AssetUsage />
                            </div>
                        </div>
                    </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14"></div>
            </Dialog>
        </Transition.Root>
    )
}
