import AssetUsage from './AssetUsage'
import AvatarMenu from './AvatarMenu'
import NavLink from './NavLink'

export default function Sidebar({ navigation }) {
    return (
        <div className="hidden md:flex md:flex-shrink-0">
            <div className="flex flex-col w-64">
                <div className="flex flex-col h-0 flex-1 bg-gray-light">
                    <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        <div className="flex items-center flex-shrink-0 px-4">
                            <img
                                className="h-10 w-auto"
                                src="tovera-logo.svg"
                                alt="tovera logo"
                            />
                        </div>
                        <nav className="mt-10 flex-1 px-2 space-y-1">
                            {navigation.map((item) => (
                                <NavLink
                                    key={item.name}
                                    href={item.href}
                                    activeClassName="text-black group flex items-center px-2 py-2 text-sm font-medium"
                                >
                                    <a className="text-gray-medium hover:text-black group flex items-center px-2 py-2 text-sm font-medium">
                                        <item.icon
                                            className="mr-2 flex-shrink-0 h-6 w-6"
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </a>
                                </NavLink>
                            ))}
                        </nav>
                    </div>
                    <div className="p-4 border-t border-gray-border">
                        <AvatarMenu />
                        <div className="mt-2">
                            <AssetUsage />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
