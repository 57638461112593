import classNames from '../lib/classNames';

function calculatePercentage(value, max) {
    const percentage = (value / max) * 100;
    return percentage.toFixed(2);
}

export default function ProgressBar({max, value, className, isPercentage}) {
    const width = isPercentage ? value : calculatePercentage(value, max);
    if (!max || !value) return null;

    return (
        <div className={classNames(className ? className : 'pt-1')}>
            <div className="overflow-hidden h-1 mb-2 text-xs flex rounded bg-blue-200">
                <div
                    style={{width: `${width}%`}}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                ></div>
            </div>
        </div>
    );
}
