import {useState, useEffect} from 'react';
import {useCurrentAccount} from '../context/CurrentAccountContext';
import {useLocaleContextActions} from 'tovera-shared/context/LocaleContext';
import ProgressBar from './ProgressBar';
import {isNumber} from 'lodash';

export default function AssetUsage() {
    const {translate} = useLocaleContextActions();
    const currentAccount = useCurrentAccount();
    const [assetCount, setAssetCount] = useState(0);

    useEffect(() => {
        if (currentAccount === undefined) return;

        // If the current account is null, we do not have any attributes yet.
        // The asset count is zero padded.
        setAssetCount(
            isNumber(currentAccount?.asset_count)
                ? currentAccount.asset_count + 1
                : 0
        );
    }, [currentAccount]);

    return (
        <>
            <div className="text-gray-medium text-xs">
                {translate('asset_usage.x_of_y_used', {
                    assetCount,
                    assetMax: process.env.NEXT_PUBLIC_ASSET_MAX
                })}
            </div>
            <ProgressBar
                value={assetCount}
                max={process.env.NEXT_PUBLIC_ASSET_MAX}
            />
        </>
    );
}
