export default function Loading() {
    return (
        <>
            <div
                className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden bg-gray-700 flex flex-col items-center justify-center"
                style={{ zIndex: 9999, color: "#4d5665" }}
            >
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
            </div>
        </>
    )
}
