import { useRouter } from 'next/router'
import Link from 'next/link'
import React, { Children } from 'react'

export default function NavLink({ children, activeClassName, ...props }) {
    const { asPath } = useRouter()
    const child = Children.only(children)
    const childClassName = child.props.className || ''
    const current = asPath === props.href || asPath === props.as
    const className = current ? activeClassName : childClassName
    const ariaCurrent = current ? 'page' : undefined

    return (
        <Link {...props}>
            {React.cloneElement(child, {
                className: className || null,
                'aria-current': ariaCurrent,
            })}
        </Link>
    )
}
