import {useState, useEffect} from 'react';
import {useMsal} from '@azure/msal-react';
import {toast} from 'react-toastify';
import {usePopper} from 'react-popper';
import {useForm} from 'react-hook-form';
import {Popover} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {
    UserIcon,
    KeyIcon,
    LoginIcon,
    PencilAltIcon
} from '@heroicons/react/outline';
import {b2cPolicies} from '../lib/b2cPolicies';
import fetchWithToken from '../lib/fetchWithToken';
import {useCurrentAccount} from '../context/CurrentAccountContext';
import {useLocaleContextActions} from 'tovera-shared/context/LocaleContext';
import Modal from './Modal';
import {API_ENDPOINTS} from '../helpers/constants';

export default function AvatarMenu() {
    const {translate} = useLocaleContextActions();
    const {instance} = useMsal();
    const account = instance.getActiveAccount();
    const currentAccount = useCurrentAccount();
    const [user, setUser] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState();
    const [popperElement, setPopperElement] = useState();
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: 'top-start'
    });
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors, isSubmitting}
    } = useForm();

    useEffect(async () => {
        if (!currentAccount) {
            return;
        }

        setUser(currentAccount);
    }, [currentAccount]);

    const popperStyles = {
        ...styles.popper,
        right: 0
    };

    function editPassword() {
        const editPasswordRequest = b2cPolicies.authorities.forgotPassword;
        instance.loginRedirect(editPasswordRequest);
    }

    function editProfile() {
        const editProfileRequest = b2cPolicies.authorities.editProfile;
        instance.loginRedirect(editProfileRequest);
    }

    function editUsername() {
        setModalOpen(true);
    }

    function handleLogout() {
        instance.logoutRedirect();
    }

    async function onSubmit(data) {
        await fetchWithToken(API_ENDPOINTS.users, {
            method: 'POST',
            body: JSON.stringify(data)
        });
        toast.success('Public Display Name updated', {autoClose: 2000});
        setModalOpen(false);
    }

    if (!account) return null;

    const givenName = account.idTokenClaims?.given_name
        ? `${account.idTokenClaims?.given_name} `
        : '';
    const familyName = account.idTokenClaims?.family_name || '';

    const initial = givenName ? givenName[0] : '';
    const displayName = `${givenName}${familyName}`;

    return (
        <>
            <Modal
                title="Edit Public Display Name"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label
                            htmlFor="display_name"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {translate('avatar_menu.public_display_name')}
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                defaultValue={user?.display_name}
                                {...register('display_name', {
                                    maxLength: {
                                        value: 30,
                                        message:
                                            'Your username cannot be longer than 30 characters.'
                                    }
                                })}
                                className="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm focus:ring-0 focus:outline-none focus:border-gray-400 border-gray-300 rounded-md"
                            />
                            {errors.display_name && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="username-error"
                                >
                                    {errors.display_name?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="mt-4 flex justify-items-end">
                        <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black bg-primary outline-none focus:outline-none disabled:opacity-50"
                        >
                            {translate('common.save')}
                        </button>
                    </div>
                </form>
            </Modal>
            <Popover className="relative">
                <Popover.Button
                    className="avatar-menu-button w-full focus:ring-0 focus:outline-none group"
                    ref={setReferenceElement}
                >
                    <div className="flex items-center">
                        <div className="w-9 h-9 rounded-md inline-block border border-gray-300 group-hover:border-gray-400 font-display flex items-center justify-center text-sm">
                            <span className="text-gray-600 group-hover:text-black">
                                {initial}
                            </span>
                        </div>
                        <div className="avatar-display-name-container text-sm overflow-ellipsis overflow-hidden text-gray-500 group-hover:text-black group-focus:text-black">
                            <div className="avatar-display-name">
                                {displayName}
                            </div>
                        </div>
                        <ChevronDownIcon className="avatar-icon h-5 w-5 text-gray-400 group-hover:text-gray-600" />
                    </div>
                </Popover.Button>
                <Popover.Panel
                    ref={setPopperElement}
                    style={popperStyles}
                    {...attributes.popper}
                >
                    <div className="overflow-hidden rounded-md shadow-lg mb-2 bg-white border border-gray-border">
                        <ul>
                            <li>
                                <a
                                    href="#"
                                    onClick={editPassword}
                                    className="flex items-center text-sm text-gray-600 hover:bg-gray-light group px-4 py-2"
                                >
                                    <KeyIcon className="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-700" />
                                    <span className="group-hover:text-black">
                                        {translate(
                                            'avatar_menu.change_password'
                                        )}
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    onClick={editProfile}
                                    className="flex items-center text-sm text-gray-600 hover:bg-gray-light group px-4 py-2"
                                >
                                    <UserIcon className="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-700" />
                                    <span className="group-hover:text-black">
                                        {translate('avatar_menu.edit_profile')}
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    onClick={editUsername}
                                    className="flex items-center text-sm text-gray-600 hover:bg-gray-light group px-4 py-2"
                                >
                                    <PencilAltIcon className="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-700" />
                                    <span className="group-hover:text-black">
                                        {translate(
                                            'avatar_menu.change_public_name'
                                        )}
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    onClick={handleLogout}
                                    className="flex items-center text-gray-600 text-sm group px-4 py-2"
                                >
                                    <LoginIcon className="mr-2 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-700" />
                                    <span className="group-hover:text-black">
                                        {translate('avatar_menu.logout')}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </Popover.Panel>
            </Popover>
        </>
    );
}
